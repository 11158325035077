/*eslint constructor-super: 2*/
/*eslint-disable constructor-super*/

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import 'moment/locale/it';
import DropDown from '../DropDown';

import SwiperCore, { Pagination, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import MyDTPicker from '../TimePicker';

import style from './style.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { Tickets as TicketsAPI, StaticContent as StaticContentAPI } from '../../Api.js';

import LazyImg from '../LazyImg';
import Cta from '../Cta';
import { Link } from 'react-router-dom';

import { Close, Minus, Info } from '../Icons';

SwiperCore.use([Pagination, Parallax]);

class Receipts extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.maxFileSize = 5000000;
        this.allowedFileEtxs = ['pdf', 'jpg', 'jpeg', 'png'];
        this.onChange = this.onChange.bind(this);
        this.urlProduct = `${process.env.REACT_APP_STATIC_CONTENT}`+'/product/products.json';
        this.slides = [
            {
                image: '/imgs/receipts/step-1.svg',
                text: 'Metti a fuoco lo scontrino e scatta una foto nitida',
            },
            {
                image: '/imgs/receipts/step-2.svg',
                text: 'Verifica che si leggano bene le informazioni sul punto vendita e i prodotti',
            },
            {
                image: '/imgs/receipts/step-3.svg',
                text: 'In caso lo scontrino sia lungo, scatta e carica un’altra foto in sequenza',
            },
        ];
        
        
        this.state = {
            isNewReceipt: true,
            step: 0,
            error: null,
            receipts: [],
            data: [],
            hasFollowUp: false,
            product: [{ id: 0, name: 'Scegli prodotto' }],
            scontrino: '',
            imageNumber: 0,
            imageUrl1: '',
            imageUrl2: '',
            length: 0,
            compiled: false,
            imageLoaded: false,
            decimalAmount: true,
            scontrinoInfo: false,
            productSelected: false,
            dateSelected: false,
            timeSelected: false,
            totalSelected: false,
            nscontrinoSelected: false,
            activeProduct: 1,
            activeProductArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            ore: "",
            minuti: "",
            total: '',
        };

        this.errorMessage = 'errore generico';

        this.inputProps = {
            placeholder: 'Inserisci data dello scontrino',
            className: 'inputScontrino',
            required: true,
        };
    }
    errorEdit(errorMsg){
        let string = 'uesti dati sono già stati giocati';
        console.log("errorMsg: ");
        console.log(errorMsg);
        if(errorMsg.includes(string)){
            errorMsg = errorMsg.replace("Questi dati sono già stati giocati", "Questi dati sono già stati giocati.");
            errorMsg = errorMsg + ' <br> Carica un nuovo scontrino per ricevere altri punti D!';
        }
        return errorMsg;
    }
    componentDidMount() {
        StaticContentAPI.get('/product/products.json').then((res) => {
            const products = res.data;
            this.setState((prevState) => ({
                product: prevState.product.concat(res.data),
            }));
        });

    }

    componentDidUpdate(prevProps) {
        if (prevProps.context.state.receipts !== this.props.context.state.receipts) {
            if (!!this.props.context.state.receipts) {
                disableBodyScroll(this.wrapperRef.current);
            } else {
                enableBodyScroll(this.wrapperRef.current);
            }
            var isNewReceipt = this.props.context.state.receipt_info==undefined ? true : false
            console.log("questo", this.props.context.state.receipt_info==undefined);
            this.setState({
                isNewReceipt: isNewReceipt,
                step: isNewReceipt ? 0 : 5,
                error: null,
                receipts: [],
                data: [],
                hasFollowUp: this.displayFollowUp(),
            });
            console.log("props2", this.props.context.state.receipt_info);
            console.log(this.props.context.state.receipt_info==undefined ? true : false)
            if(!isNewReceipt){
                var receipt_info = this.props.context.state.receipt_info

                let [date, time] = receipt_info.receipt_date.split(' ');
                time = time.split(':').slice(0, 2).join(':');

                document.getElementById('product_id').value = receipt_info.id ? receipt_info.id : 1;
                document.getElementById('orarioScontrino').value = time;
                document.getElementById('amount').value = receipt_info.receipt_amount;
                document.getElementById('dataScontrino').value = date;
                document.getElementById('nscontrino').value = receipt_info.receipt_number;
                
            }
            

            {/* <input type="hidden"  name="product_id" id="product_id"/>
            <input type="hidden" name="orarioScontrino" class="orarioScontrino" id="orarioScontrino" onClick={this.unlockTime.bind(this)} />
            <input type="hidden"  name="amount" class="amount" id="amount"/>
            <input type="hidden" name="dataScontrino" class="dataScontrino" id="dataScontrino"/>
            <input type="hidden" name="nscontrino" id="nscontrino" class="nscontrino" />
            <input type="hidden" id="file_url" value={this.state.imageUrl1} />
            <input type="hidden" id="file_url_2" value={this.state.imageUrl2} /> */}
        }
    }

    displayFollowUp() {
        const { user } = this.props.context.state;

        if (user.data.legal && !!user.data.legal.profiling) {
            return (
                !!user &&
                (user.data.dishwasher === undefined ||
                    user.data.shops === undefined ||
                    user.data.shops.length === 0 ||
                    !user.address ||
                    !user.city ||
                    !user.zip ||
                    !user.state ||
                    !user.data.region)
            );
        } else {
            return false;
        }
    }

    onChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ scontrino: e.target.value });
        }
    }

    unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }

    save() {
        //send data to API OCR through POST method
        const { dispatch } = this.props.context;
        dispatch({ type: 'SET_LOADING' });

        let product_id = parseInt(document.getElementById('product_id').value);
        let dataScontrino = document.getElementById('dataScontrino').value;

        let orarioScontrino = document.getElementById('orarioScontrino').value;
        dataScontrino = dataScontrino+' '+orarioScontrino+':00';

        let amount = parseFloat(document.getElementById('amount').value);
        let nscontrino = document.getElementById('nscontrino').value;
        let img1 = document.getElementById('file_url').value;
        let img2 = document.getElementById('file_url_2').value;
        let file_url,
            file_url_2 = '';

        if (img1 != '' && img2 === '') {
            file_url = img1;
        } else if (img1 != '' && img2 != '') {
            file_url = img1;
            file_url_2 = img2;
        } else if (img1 === '' && img2 != '') {
            file_url = img2;
        }

        const data = new FormData();

        data.append('product_id', product_id);
        data.append('number', nscontrino);
        data.append('date', dataScontrino);
        data.append('amount', amount);
        data.append('file_url', file_url);
        data.append('file_url_2', file_url_2);

        if(this.state.isNewReceipt){
            data.append('isNewReceipt', "true");
            TicketsAPI.post('verify', data)
            .then((response) => {
                //debugger;
                console.log('then: ');
                console.log(response);
                if (typeof response.data != 'undefined' && response.data.success) {
                    this.setState(
                        {
                            step: 6,
                            error: null,
                        },
                        () => dispatch({ type: 'UNSET_LOADING' })
                    );
                    this.setForm();
                    if (typeof window.dataLayer != 'undefined') {
                        window.dataLayer.push({
                            event: 'PWA',
                            category: 'PWA',
                            action: 'Receipt uploaded',
                            name: `${this.state.receipts.length} photos uploaded`,
                        });
                    }
                } else {
                    console.log('regular: ');
                    console.log(response);
                    var step = 7;
                    if (typeof response.response.data.error !== undefined) {
                        var temp = response.response.data.error.split('response:\n');
                        temp[1].replace('\n', '');
                        var substr = temp[1].match(/"message":"[\w\ \\\\.']*"/);
                        var messageA = substr[0].split(':');
                        var message = messageA[1].replaceAll('"', '');


                        //let errors  = JSON.parse(temp[1])
                        this.errorMessage = 'Si è verificato un errore: <br><br>';
                        this.errorMessage +=
                            '<strong>' + this.unicodeToChar(message) + '</strong><br>';
                        console.log(message);

                        step = 8;
                    }
                    this.setForm();
                    this.setState(
                        {
                            step: step,
                            error: null,
                        },
                        () => dispatch({ type: 'UNSET_LOADING' })
                    );
                }
            })
            .catch((error) => {
                console.log('catch: ');
                console.log(error);
                this.setState(
                    {
                        step: 7,
                        error: null,
                    },
                    () => dispatch({ type: 'UNSET_LOADING' })
                );
            });

        }else{
            data.append('isNewReceipt', false);
            data.append('upload_id', this.props.context.state.receipt_info.upload_id);
            console.log("data",data);
            TicketsAPI.post('update-image', data)
            .then((response) => {
                //debugger;
                console.log('then: ');
                console.log(response);
                if (typeof response.data != 'undefined' && response.data.success) {
                    this.setState(
                        {
                            step: 9,
                            error: null,
                        },
                        () => dispatch({ type: 'UNSET_LOADING' })
                    );
                }else{
                    this.setState(
                        {
                            step: 7,
                            error: null,
                        },
                        () => dispatch({ type: 'UNSET_LOADING' })
                    );
                }
                
            })
            .catch((error) => {
                console.log('catch: ');
                console.log(error);
                this.setState(
                    {
                        step: 7,
                        error: null,
                    },
                    () => dispatch({ type: 'UNSET_LOADING' })
                );
            });

        }
        
    }

    validateReceiptField(field, type) {
        console.log(field);
        if (field == null || field == '') {
            console.log(field + ' NOT valid');
            return false;
        }

        return true;
    }

    onChangeValidateReceiptFields(e) {
        let dataScontrino = document.getElementById('dataScontrino');
        let datePreview = document.getElementById('datePreview');
        
        console.log('e.target');
        console.log(e.format('YYYY-MM-DD'));

        dataScontrino.value = e.format('YYYY-MM-DD');
        
        this.setState({dateSelected: true});
        //return this.validateReceiptFields();
    }
    onChangeValidateNScontrino(e) {
        this.onChange(e);
        this.onChangeValidateReceiptFields(e);
    }
    

    validateReceiptFields() {
        let product_id = Number(document.getElementById('product_id').value);
        
        let dataScontrino = document.getElementById('dataScontrino').value;
        let orarioScontrino = document.getElementById('orarioScontrino').value;
        let amountField = document.getElementById('amount');
        amountField.value = amountField.value.replace(',', '.');
        let amount = document.getElementById('amount').value;
        let nscontrino = document.getElementById('nscontrino').value;

        this.setState({ compiled: true });
        this.state.compiled = true;
        if (!this.validateReceiptField(product_id, 'number')) {
            this.setState({ compiled: false });
            this.state.compiled = false;
        }
        if (!this.validateDate(dataScontrino)) {
            this.setState({ compiled: false });
            this.state.compiled = false;
        }
        if (!this.validateTime(orarioScontrino)) {
            this.setState({ compiled: false });
            this.state.compiled = false;
        }
        if (!this.validateAmount(amount)) {
            this.setState({ compiled: false });
            this.state.compiled = false;
        }
        if (!this.validateReceiptNumber(nscontrino)) {
            this.setState({ compiled: false });
            this.state.compiled = false;
        }

        console.log('this.state.compiled: ' + this.state.compiled);
        return this.state.compiled;
    }
    validateReceiptNumber(nscontrino) {
        let re = /^([0-9]{8}|[0-9]{10})$/;
        if(this.validateRe(nscontrino, re)){
            this.setState({nscontrinoSelected: true});
            return true;
        }
        this.setState({nscontrinoSelected: false});
        return false;
    }
    
    validateAmount(amount) {
        
        let re = /^[0-9]*(\.[0-9]{2}?)$/;
        if (this.validateRe(amount, re)) {
            this.state.decimalAmount = true;
            this.setState({totalSelected: true});
            return true;
        }
        if (amount !== '') {
            this.state.decimalAmount = false;
            this.setState({totalSelected: false});
        }
        return false;
    }
    validateDate(date) {
        let re = /^([0-9]{4})-([0]{1}[0-9]{1}|[1]{1}[0-2])-([0-2]{1}[0-9]{1}|[3]{1}[0-1]{1})$/;
        return this.validateRe(date, re);
    }

    validateTime(time){
        let re = /^([0-1][0-9]{1}|[2][0-3]{1}):([0-5]{1}[0-9]{1})$/;
        return this.validateRe(time,re);
    }

    validateRe(field, re) {
        console.log(field);
        if (field != '' && re.test(field)) {
            return true;
        }
        console.log(field + ' NOT valid');
        return false;
    }

    async uploadFile(file, index) {
        // upload file to S3 bucket
        const { dispatch } = this.props.context;
        dispatch({ type: 'SET_LOADING' });

        let product_id = parseInt(document.getElementById('product_id').value);
        let dataScontrino = document.getElementById('dataScontrino').value;
        let orarioScontrino = document.getElementById('orarioScontrino').value;

        dataScontrino = dataScontrino+' '+orarioScontrino+':00';

        let amount = parseFloat(document.getElementById('amount').value);
        let nscontrino = document.getElementById('nscontrino').value;

        const data = new FormData();
        data.append('file', file);
        data.append('product_id', product_id);
        data.append('number', nscontrino);
        data.append('date', dataScontrino);
        data.append('amount', amount);
        data.append('index', index);
        data.append('isNewReceipt', this.state.isNewReceipt ? "true" : "false");
        //debugger;
        await TicketsAPI.post('upload', data).then((res) => {

                this.state.imageLoaded = false;
                console.log(res);
                console.log(res.data.message);
                if (res && res.data && typeof res.data.image !== 'undefined') {
                    this.state.imageLoaded = true;
                    index == 1
                        ? this.setState({ imageUrl1: res.data.image }, () =>
                              dispatch({ type: 'UNSET_LOADING' })
                          )
                        : this.setState({ imageUrl2: res.data.image }, () =>
                              dispatch({ type: 'UNSET_LOADING' })
                          );
                } else{
                    dispatch({ type: 'UNSET_LOADING' });
                }

                if(res.data.message==='Error: Receipt already exists.'){
                    console.log('Receipt already exists');
                }

            }).catch((error) => {
                console.log("siamo nel catch");
                console.log(error);
                console.log(error.response.status);
                if(error.response.status===409){
                    console.log('Receipt already exists');
                    dispatch({
                        type: 'TOGGLE_MESSAGE_ALERT', 
                        payload: {
                            message: {
                                    title: "Hai già caricato questo scontrino!",
                                    description: "Se desideri modificare l'immagine, accedi alla sezione 'I miei scontrini'.",
                                    cta: {
                                        label: 'Vai ai miei scontrini',
                                        onClick: () => window.location.href = '/scontrini',
                                        modifiers: ['primary'],
                                    },
                                }
                            }    
                    });
                }
            });
        dispatch({ type: 'UNSET_LOADING' });
    }

    validateFile(file) {
        const ext = file.name.split('.').pop();
        if (file.size > this.maxFileSize) {
            this.setState({
                error: `Attenzione il file supera la dimensione massima consentita di ${
                    this.maxFileSize / 1000000
                }MB`,
            });
            return false;
        } else if (this.allowedFileEtxs.indexOf(ext) === -1) {
            this.setState({
                error: `Attenzione file non supportato (tipi supportati: ${this.allowedFileEtxs.join(
                    ', '
                )})`,
            });
            return false;
        } else {
            return true;
        }
    }
    onClickFile(e) {
        if (!this.validateReceiptFields(e)) {
            alert('Compilare tutti i campi prima di continuare');
            e.disabled = true;
            return false;
        }
        e.disabled = false;
        return true;
    }
    onClickProductFamily(e){
        
        this.setState({productSelected: true});
        console.log(e.target);
        let product_id = document.getElementById('product_id');
        product_id.value = e.target.getAttribute('identifier');
        
        let allActive = document.getElementsByClassName('activeProduct');
        let intCount = 0;

        let activeProductArray = this.state.activeProductArray;
        
        if(this.state.activeProductArray[product_id.value] == 0){
            activeProductArray[product_id.value] = product_id.value;
            this.setState({activeProductArray: activeProductArray})
        }else{
            activeProductArray[product_id.value] = 0;
            this.setState({activeProductArray: activeProductArray})
        }
        
    }
    onLoadDate(e){

        let dataScontrino = document.getElementById('dataScontrino');
        let inputScontrino = document.getElementsByClassName('inputScontrino');

        if(dataScontrino.value !== undefined && dataScontrino.value !== '' && (inputScontrino[0] == undefined || inputScontrino[0].value == undefined || inputScontrino[0].value == '')  ){
            return dataScontrino.value;
        }

        
    }
    onChangeAmount(){
        let total = document.getElementById('total').value;
        total = total.replace(',', '.');
        let amount = document.getElementById('amount');
        amount.value = total;
        console.log('amount: '+total);
        this.state.total = total;
        this.setState({total: total});
        this.validateAmount(total);
    }
    
    onChangeNumber(){
        let nscontrinoInner = document.getElementById('nscontrinoInner').value;
        let nscontrino = document.getElementById('nscontrino');
        this.state.scontrino = nscontrinoInner;
        this.setState({scontrino: nscontrinoInner})
        nscontrino.value = nscontrinoInner;
        
        this.validateReceiptNumber(nscontrinoInner);
    }

    handleOreChange(event){
        console.log(event.target.value);
        let ore = event.target.value;
        console.log(isNaN(ore));
        if(ore > 23 || ore < 0 || isNaN(ore)){
            ore = "";
        }
        this.state.ore = ore;
        this.setState({ore: ore});
        this.mergeOreMinuti();
    }
    handleMinutiChange(event){
        console.log(event.target.value);
        let minuti = event.target.value;
        console.log(isNaN(minuti));
        if(minuti > 59 || minuti < 0 || isNaN(minuti)){
            minuti = "";
        }
        this.state.minuti = minuti;
        this.setState({minuti: minuti});
        this.mergeOreMinuti();
    }
    mergeOreMinuti(){
        
        if(this.state.ore && this.state.minuti){
            this.state.ore = ("0" + this.state.ore).slice(-2);
            this.state.minuti = ("0" + this.state.minuti).slice(-2);
            document.getElementById('orarioScontrino').value = this.state.ore+':'+this.state.minuti;
            this.setState({ timeSelected: true });
        }else{
            this.setState({ timeSelected: false });
        }
        console.log(this.state.ore+':'+this.state.minuti);
      
    }
    onInputChange(e) {
        const input = e.target;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            if (this.validateFile(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async (e) => {
                    if (this.validateReceiptFields()) {
                        await this.uploadFile(file, this.state.imageNumber);
                        if (this.state.imageLoaded) {
                            this.setState({
                                error: false,
                                receipts: [
                                    ...this.state.receipts,
                                    {
                                        file: file,
                                        ext: file.name.split('.').pop(),
                                        src: e.target.result,
                                    },
                                ],
                                length: this.state.length + 1,
                                imageNumber: this.state.imageNumber + 1,
                            });
                        }

                        input.value = null;
                    } else {
                        alert('Compilare tutti i campi prima di caricare il file');
                    }
                };
            }
        }
    }

    setProduct(){
        this.setState({ step: 1 });
    }

    setForm() {
        this.state.imageUrl1 = '';
        this.state.imageUrl2 = '';
        this.state.scontrino = '';
        this.setState({ scontrino: '' });
        this.setState({ total: '' });
        this.setState({ dateSelected: false });
        this.setState({ productSelected: false });
        this.setState({ totalSelected: false });
        this.setState({ nscontrinoSelected: false });
        this.setState({ activeProduct: 1 });
        this.setState({ activeProductArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
        this.state.total = '';
        let dataScontrino = document.getElementById('dataScontrino');
        dataScontrino.value = '';
        let orarioScontrino = document.getElementById('orarioScontrino');
        orarioScontrino.value = '';
        this.removeImage(0);
    }

    resetForm(){
        this.state.imageUrl1 = '';
        this.state.imageUrl2 = '';
        this.state.scontrino = '';
        this.setState({ scontrino: '' });
        this.setState({ total: '' });
        this.setState({ dateSelected: false });
        this.setState({ productSelected: false });
        this.setState({ totalSelected: false });
        this.setState({ nscontrinoSelected: false });
        this.setState({ activeProduct: 1 });
        this.setState({ activeProductArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
        this.state.total = '';
        let dataScontrino = document.getElementById('dataScontrino');
        dataScontrino.value = '';
        let orarioScontrino = document.getElementById('orarioScontrino');
        orarioScontrino.value = '';
        this.removeImage(0);
        console.log('SETTING FORM');
        this.setState({ step: 0 });
    }

    setDate(){
        this.setState({ step: 2 });
    }
    setTime(){
        this.setState({ step: 3 });
    }
    setTotal(){
        this.setState({ step: 4 });
    }
    setTicketNumber(){
        this.setState({ step: 5 });
    }

    removeImage(index) {
        this.setState({
            error: false,
            receipts: this.state.receipts.filter((a, i) => i !== index),
        });
        index == 0
            ? this.setState({ imageUrl1: '', imageNumber: index })
            : this.setState({ imageUrl2: '', imageNumber: index });
    }

    toggleInfo() {
        if(this.state.scontrinoInfo){
            this.setState({ scontrinoInfo: false });
        }else{
            this.setState({ scontrinoInfo: true });
        }
    }

    render() {
        const { state, dispatch } = this.props.context;
        return (
            <React.Fragment>
                <CSSTransition in={state.receipts} timeout={200} classNames="popup" unmountOnExit>
                    <div className={style.wrapper} ref={this.wrapperRef}>
                        <div className={style.container}>
                            <div className={style.inner + ' ' +  style.innerFirst}>
                                <span
                                    className={style.close}
                                    onClick={() => dispatch({ type: 'TOGGLE_RECEIPTS' })}
                                >
                                    <Close />
                                </span>
                                
                                <input type="hidden"  name="product_id" id="product_id"/>
                                <input type="hidden" name="orarioScontrino" class="orarioScontrino" id="orarioScontrino" /* onClick={this.unlockTime.bind(this)} */ />
        
                                <input type="hidden"  name="amount" class="amount" id="amount"/>
                                <input type="hidden" name="dataScontrino" class="dataScontrino" id="dataScontrino"/>
                                <input type="hidden" name="nscontrino" id="nscontrino" class="nscontrino" />
                                <input type="hidden" id="file_url" value={this.state.imageUrl1} />
                                <input type="hidden" id="file_url_2" value={this.state.imageUrl2} />

                                {this.state.step === 0 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                        <div className={style.progressBar}>
                                            <div className={style.progressBarInner + ' ' + style.progressBarInner0}></div>
                                        </div>
                                            <h2>Carica Scontrino</h2>
                                            <p>
                                                Per ogni scontrino valido ti saranno attribuiti 600
                                                punti D. Sono validi quelli leggibili e che
                                                attestino l’acquisto di prodotti della famiglia:
                                                
                                                
                                            </p>
                                        </div>
                                        <div className={style.productFamilyList}>
                                                    <div className={style.productFamily}  identifier="1">
                                                        <img 
                                                        alt="Bio Presto"
                                                         src="/imgs/receipts/products/biopresto.png" identifier="1"
                                                         onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                         className={this.state.activeProductArray[1] == 1 ? 'activeProduct' : ''}
                                                         />
                                                        <p><small>Bio Presto</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="2">
                                                        <img alt="Bref" src="/imgs/receipts/products/bref.png" 
                                                        onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        identifier="2"
                                                        className={this.state.activeProductArray[2] == 2 ? 'activeProduct' : ''}/>
                                                        <p><small>Bref</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="3">
                                                        <img alt="Brillance" src="/imgs/receipts/products/brillance.png" 
                                                        onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[3] == 3 ? 'activeProduct' : ''}
                                                        identifier="3"/>
                                                        <p><small>Brillance</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="4">
                                                        <img alt="Coloreria Italiana" src="/imgs/receipts/products/coloreria.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[4] == 4 ? 'activeProduct' : ''} 
                                                        identifier="4"/>
                                                        <p><small>Coloreria Italiana</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="5">
                                                        <img alt="Dixan" src="/imgs/receipts/products/dixan.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )} 
                                                        className={this.state.activeProductArray[5] == 5 ? 'activeProduct' : ''}
                                                        identifier="5"/>
                                                        <p><small>Dixan</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="6">
                                                        <img alt="Gliss" src="/imgs/receipts/products/gliss.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )} 
                                                        className={this.state.activeProductArray[6] == 6 ? 'activeProduct' : ''}
                                                        identifier="6"/>
                                                        <p><small>Gliss</small></p>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div className={style.productFamily}  identifier="9">
                                                        <img alt="Palette" src="/imgs/receipts/products/palette.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[9] == 9 ? 'activeProduct' : ''}
                                                        identifier="9"/>
                                                        <p><small>Palette</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="10">
                                                        <img alt="Perlana" src="/imgs/receipts/products/perlana.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[10] == 10 ? 'activeProduct' : ''}
                                                        identifier="10"/>
                                                        <p><small>Perlana</small></p>
                                                    </div>
                                                    
                                                    <div className={style.productFamily}  identifier="11">
                                                        <img alt="Pril" src="/imgs/receipts/products/pril.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[11] == 11 ? 'activeProduct' : ''}
                                                         identifier="11"/>
                                                        <p><small>Pril</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="12">
                                                        <img alt="Schwarzkopf" src="/imgs/receipts/products/schwarzkopf.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[12] == 12 ? 'activeProduct' : ''}
                                                        identifier="12"/>
                                                        <p><small>Schwarzkopf</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="13">
                                                        <img alt="Vernel" src="/imgs/receipts/products/vernel.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[13] == 13 ? 'activeProduct' : ''}
                                                        identifier="13"/>
                                                        <p><small>Vernel</small></p>
                                                    </div>
                                                    
                                                    <div className={style.productFamily}  identifier="14">
                                                        <img alt="got2b" src="/imgs/receipts/products/got2b.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )} 
                                                        className={this.state.activeProductArray[14] == 14 ? 'activeProduct' : ''}
                                                        identifier="14"/>
                                                        <p><small>got2b</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="15">
                                                        <img alt="Natural & Easy" src="/imgs/receipts/products/naturaleasy.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[15] == 15 ? 'activeProduct' : ''}
                                                         identifier="15"/>
                                                        <p><small>Natural & Easy</small></p>
                                                    </div>
                                                    <div className={style.productFamily}  identifier="8">
                                                        <img alt="L'Acchiappacolore" src="/imgs/receipts/products/acchiappacolore.png" onClick={this.onClickProductFamily.bind(
                                                            this
                                                        )}
                                                        className={this.state.activeProductArray[8] == 8 ? 'activeProduct' : ''}
                                                        identifier="8"/>
                                                        <p><small>L' Acchiappacolore</small></p>
                                                    </div>
                                                </div>
                                                <p>
                                                Per saperne di più, consulta le nostre{' '}<Link
                                                    onClick={() =>
                                                        dispatch({ type: 'TOGGLE_RECEIPTS' })
                                                    }
                                                    to="/faq"
                                                >FAQ</Link>.</p>
                                        <div className={style.controls}>
                                            <Cta
                                                label="Inizia"
                                                onClick={() => this.setProduct()}
                                                disabled={!this.state.productSelected}
                                            />
                                        </div>
                                    </div>
                                )}
                                
                                {this.state.step === 1 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <div className={style.progressBar}>
                                                <div className={style.progressBarInner + ' ' + style.progressBarInner1}></div>
                                            </div>
                                            <h2>Seleziona la data</h2>
                                            <div ></div>
                                        </div>

                                        
                                        <Datetime
                                            locale="it"
                                            ref="date"
                                            inputProps={this.inputProps}
                                            input={false}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            onChange={this.onChangeValidateReceiptFields.bind(this)}
                                            initialValue={this.onLoadDate()}
                                        />
                                        
                                        
                                        <div className={style.controls}>
                                            <Cta
                                                label="indietro"
                                                modifiers={['secondary']}
                                                onClick={() => this.setState({step: 0})}
                                            />
                                            <Cta
                                                label="avanti"
                                                onClick={() => this.setDate()}
                                                disabled={!this.state.dateSelected}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 2 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <div className={style.progressBar}>
                                                <div className={style.progressBarInner + ' ' + style.progressBarInner2}></div>
                                            </div>
                                            <h2>Seleziona l'ora</h2>
                                        </div>
                                        <p className={style.textFeedback}>
                                            Inserisci l'orario riportato sullo scontrino, includendo sia l'ora che i minuti.<br></br>
                                            L'orario va da 00:00 a 23:59, come indicato sullo scontrino.
                                        </p>                             
                                        {/* <MyDTPicker/> */}
                                        <div className={style.hours}>
                                            <input type="text" id="ore" name="ore" maxLength={2} onChange={this.handleOreChange.bind(this)} value={this.state.ore} placeholder='00' required></input>
                                            <span>:</span>
                                            <input type="text" id="minuti" name="minuti" maxLength={2} onChange={this.handleMinutiChange.bind(this)} value={this.state.minuti} placeholder='00' required></input>
                                        </div>
                                        <div className={style.controls}>
                                            <Cta
                                                label="indietro"
                                                modifiers={['secondary']}
                                                className={style.btnBack}
                                                onClick={() => this.setState({step: 1})}
                                            />
                                            <Cta
                                                label="avanti"
                                                onClick={() => this.setTime()}
                                                disabled={!this.state.timeSelected}
                                            />
                                        </div>
                                    </div>

                                )}
                                {this.state.step === 3 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <div className={style.progressBar}>
                                                <div className={style.progressBarInner + ' ' + style.progressBarInner3}></div>
                                            </div>
                                            <h2>Inserisci il totale dello scontrino</h2>
                                        </div>
                                        <input
                                            required
                                            type="text"
                                            id="total"
                                            placeholder="0.00"
                                            value={this.state.total}
                                            onChange={this.onChangeAmount.bind(this)}
                                        />
                                        
                                        <div
                                            className={
                                                true
                                                    ? 'active ' + style.text
                                                    : 'active ' + style.text
                                            }
                                        >
                                            <p className={style.textFeedback}>
                                                Inserisci la cifra dello scontrino per intero, compresi i decimali.
                                            </p>
                                        </div>
                                        <div className={style.controls}>
                                            <Cta
                                                label="indietro"
                                                modifiers={['secondary']}
                                                className={style.btnBack}
                                                onClick={() => this.setState({step: 2})}
                                            />
                                            <Cta
                                                label="avanti"
                                                onClick={() => this.setTotal()}
                                                disabled={!this.state.totalSelected}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 4 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <div className={style.progressBar}>
                                                <div className={style.progressBarInner + ' ' + style.progressBarInner4}></div>
                                            </div>
                                            <h2>Inserisci il numero dello scontrino</h2>
                                        </div>
                                        <div class={style.inputContainer}>
                                            <input
                                                required
                                                type="text"
                                                id="nscontrinoInner"
                                                minLength={8}
                                                maxLength={10}
                                                value={this.state.scontrino}
                                                onChange={this.onChangeNumber.bind(this)}
                                                placeholder="Inserisci numero"
                                                
                                            />
                                            
                                        </div>
                                        <div
                                            className={
                                                true
                                                    ? 'active ' + style.text
                                                    : 'active ' + style.text
                                            }
                                        >
                                            <p className={style.textFeedback}>
                                            Il numero dello scontrino si trova solitamente <strong>vicino alla data e all'ora dell'emissione</strong>.È composto da 8 o 10 cifre, inclusi gli eventuali zeri, da inserire senza il trattino, spesso preceduto dalla sigla “N°” o “Numero”.
                                            </p>
                                        </div>
                                        
                                        <div className={style.controls}>
                                            <Cta
                                                label="indietro"
                                                modifiers={['secondary']}
                                                className={style.btnBack}
                                                onClick={() => this.setState({step: 3})}
                                            />
                                            <Cta
                                                label="avanti"
                                                onClick={() => this.setTicketNumber()}
                                                disabled={!this.state.nscontrinoSelected}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 5 && (
                                    <div className={style.section + ' ' + style.imageSection}>
                                        <div className={style.header}>
                                            <div className={style.progressBar}>
                                                <div className={style.progressBarInner + ' ' + style.progressBarInner5}></div>
                                            </div>
                                            <h2>Inserisci le immagini</h2>
                                            <p>È possibile caricare lo scontrino nei formati png, jpeg o pdf.</p>
                                        </div>
                                        <div className={style.receipts}>
                                            <Swiper spaceBetween={8} parallax={true}>
                                                {this.state.receipts.map((s, j) => (
                                                    <SwiperSlide key={j}>
                                                        <div
                                                            className={style.slide}
                                                            data-swiper-parallax-scale=".9"
                                                        >
                                                            <span
                                                                className={style.slideRemove}
                                                                onClick={() => this.removeImage(j)}
                                                            >
                                                                <Minus />
                                                            </span>
                                                            <div className={style.slideImage}>
                                                                <img
                                                                    src={
                                                                        s.ext !== 'pdf'
                                                                            ? s.src
                                                                            : '/imgs/receipts/document.svg'
                                                                    }
                                                                    alt={j}
                                                                />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                                <SwiperSlide>
                                                    <div
                                                        className={
                                                            style.slide +
                                                            ' ' +
                                                            (this.state.compiled
                                                                ? 'active'
                                                                : 'active')
                                                        }
                                                        data-swiper-parallax-scale=".9"
                                                    >
                                                        <div className={style.slideInner}>
                                                            <div className={style.uploadImage}>
                                                                <input
                                                                    
                                                                    onClick={this.onClickFile.bind(
                                                                        this
                                                                    )}
                                                                    onChange={this.onInputChange.bind(
                                                                        this
                                                                    )}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                        </div>
                                        <div className={style.controls}>
                                            {this.state.error && (
                                                <p className={style.warning}>
                                                    <Info />
                                                    {this.state.error}
                                                </p>
                                            )}

                                            <div className={style.controlsUpload + ' ' +  (this.state.compiled ? '' : 'fully-hidden-lock')}>
                                                <input
                                                    disabled={
                                                        this.state.receipts.length === 2 ||
                                                        !this.state.compiled
                                                    }
                                                    onChange={this.onInputChange.bind(this)}
                                                    onClick={this.onClickFile.bind(this)}
                                                    type="file"
                                                />
                                                <Cta
                                                    disabled={
                                                        this.state.receipts.length === 2 ||
                                                        !this.state.compiled
                                                    }
                                                    modifiers={['secondary']}
                                                    label="Aggiungi scatto"
                                                />
                                                
                                            </div>
                                            <div>
                                                
                                            </div>
                                        </div>
                                        <div className={style.controls}>
                                            {this.state.isNewReceipt && <Cta
                                                label="indietro"
                                                modifiers={['secondary']}
                                                className={style.btnBack}
                                                onClick={() => this.setState({step: 4})}
                                            />}
                                            
                                            <Cta
                                                label="Invia"
                                                disabled={
                                                    this.state.receipts.length === 0 ||
                                                    !this.state.compiled
                                                }
                                                onClick={this.save.bind(this)}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 6 && (
                                    <div className={`${style.section} ${style.followup}`}>
                                        <div className={style.header}>
                                            {!this.state.hasFollowUp && (
                                                <LazyImg src="/imgs/receipts/thanks.svg" />
                                            )}
                                            <h2>Grazie!</h2>
                                            <p>
                                                Il tuo scontrino è stato caricato correttamente.
                                                Stiamo verificando che sia leggibile e valido per questa iniziativa. Ti ricordiamo che questo scontrino non potrà essere utilizzato per altre iniziative. Entro 48h potrai vedere se è stato
                                                accettato sull'area personale "I miei scontrini".
                                            </p>
                                            <br></br>
                                            <Link
                                                onClick={() =>
                                                    dispatch({ type: 'TOGGLE_RECEIPTS' })
                                                }
                                                to="/scontrini"
                                            >
                                                Verifica scontrino
                                            </Link>
                                        </div>

                                        <div className={style.controls}>
                                            <Cta
                                                label="Chiudi"
                                                onClick={() =>
                                                    dispatch({ type: 'TOGGLE_RECEIPTS' })
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {this.state.step === 7 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <LazyImg src="/imgs/receipts/thanks.svg" />
                                            <h2>Oh no!</h2>
                                            <p>
                                                Sembra ci siano stati dei problemi con il
                                                caricamento dello scontrino.
                                            </p>
                                        </div>
                                        <div className={style.controls}>
                                            <Cta label="Indietro" modifiers={['secondary']} 
                                            className={style.btnBack} onClick={() => this.resetForm()} />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 8 && (
                                    <div className={style.section}>
                                        <div className={style.header}>
                                            <LazyImg src="/imgs/receipts/thanks.svg" />
                                            <h2>Oh no!</h2>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: this.errorEdit(this.errorMessage),
                                                }}
                                            ></p>
                                        </div>
                                        <div className={style.controls}>
                                            <Cta label="Indietro" className={style.btnBack} onClick={() => this.resetForm()} />
                                        </div>
                                    </div>
                                )}
                                {this.state.step === 9 && (
                                    <div className={`${style.section} ${style.followup}`}>
                                        <div className={style.header}>
                                            {!this.state.hasFollowUp && (
                                                <LazyImg src="/imgs/receipts/thanks.svg" />
                                            )}
                                            <h2>Grazie!</h2>
                                            <p>
                                                Il tuo scontrino è aggiornato correttamente.
                                                Abbiamo preso in carico le modifiche. Ti ricordiamo che questo scontrino non potrà essere utilizzato per altre iniziative. Entro 48h potrai vedere se è stato
                                                accettato sull'area personale "I miei scontrini".
                                            </p>
                                            <br></br>
                                            <Link
                                                onClick={() =>
                                                    dispatch({ type: 'TOGGLE_RECEIPTS' })
                                                }
                                                to="/scontrini"
                                            >
                                                Verifica scontrino
                                            </Link>
                                        </div>

                                        <div className={style.controls}>
                                            <Cta
                                                label="Chiudi"
                                                onClick={() =>
                                                    dispatch({ type: 'TOGGLE_RECEIPTS' })
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }
}

export default Receipts;
